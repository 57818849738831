import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Dropdown, Grid, Icon, Label, Popup, SemanticCOLORS, Table } from 'semantic-ui-react';
import { Contract as ContractObj, Program } from '../../../../api/types';
import AomLoader from '../../../../components/AomLoader';
import AomMessage from '../../../../components/AomMessage';
import { AomMessageType } from '../../../../components/AomMessage/AomMessage';
import AttachedFileInfo from '../../../../components/AttachedFileInfo';
import AuditUserInfo from '../../../../components/AuditUserInfo';
import ErrorMessage from '../../../../components/ErrorMessage/ErrorMessage';
import { getRuleValues, IRulesAssociation } from '../../../../services/rules/getRuleValues';
import { RuleName } from '../../../../services/rules/RuleName';
import formatDecimal from '../../../../services/utils/formatDecimal';
import { loadCarriers } from '../../../../store/carriers/carriersActions';
import { carriersByCodeSelector, carriersLoadStateSelector, } from '../../../../store/carriers/carriersSelectors';
import { LoadStateType } from '../../../../store/commonTypes';
import { showConfirmation } from '../../../../store/confirmation/confirmationActions';
import { deleteContract, loadContract, setContractStatus } from '../../../../store/contract/contractActions';
import {
  contractCarriersSelector,
  contractSelector,
  contractStatusPropsSelector,
} from '../../../../store/contract/contractSelectors';
import { ContractStatus } from '../../../../store/contractList/contractListTypes';
import { deleteProgram } from '../../../../store/program/programActionCreators';
import { ProgramStatus } from '../../../../store/program/programTypes';
import useParamsSelector from '../../../../store/useParamsSelector';
import { combinePath } from '../../../path.utils';
import { groupByLcn } from '../../CreateContract/CustomerInfo/helper';
import { ViewAccountTable } from '../../CreateContract/CustomerInfo/AccountTable/AccountTable';
import { ContractParams } from '../Contract';
import './ContractView.scss';
import GdsInfoView from './GdsInfoView';
import { mapContractToViewModel } from '../../../../store/contract/contractMapping';
import { AppDispatch, useAppDispatch } from '../../../../store/useAppDispatch';
import ExcludedPccsView from './ExcludedPccsView';
import { PropagateContractDropdownItems } from './PropagateContractDropdownItems';
import { useNavigate, useLocation, useParams, NavigateFunction, Link } from 'react-router-dom';
import { AomBreadcrumbs } from '../../../../components/AomBreadcrumbs';
import { formatDate, formatToUtcDate } from '../../../date.utils';

export const statusColorMap: Record<number, SemanticCOLORS> = {
  [ContractStatus.Draft]: 'grey',
  [ContractStatus.Active]: 'green',
  [ContractStatus.Inactive]: 'orange',
  [ContractStatus.Archived]: 'red',
};

export const programStatusColorMap: Record<number, SemanticCOLORS> = {
  [ProgramStatus.Draft]: 'grey',
  [ProgramStatus.Active]: 'green',
  [ProgramStatus.Inactive]: 'orange',
  [ProgramStatus.Archived]: 'red',
};

const getLastTicketDate = (p: Program) => {
  const dateValues = getRuleValues(p as IRulesAssociation, RuleName.ticketedDateBetween);
  if (dateValues == null || dateValues.length === 0) {
    return null;
  }
  const endDates = dateValues.map((d) => d[1]).filter((d) => d) as string[];
  if (endDates.length === 0) {
    return null;
  }
  endDates.sort((a, b) => b.localeCompare(a));
  return formatToUtcDate(endDates[0]);
};

export const getChangeStatusHandler =
  (
    dispatch: AppDispatch,
    contractId: number,
    message: string,
    buttonLabel: string,
    targetStatus: ContractStatus,
    contractName?: string
  ) =>
    () => {
      dispatch(
        showConfirmation(message, contractName, [
          {
            displayName: 'Cancel',
          },
          {
            displayName: buttonLabel,
            isPrimary: true,
            onClick: async () => {
              await dispatch(setContractStatus(contractId, targetStatus));
            },
          },
        ])
      );
    };

export const deleteContractHandler =
  (
    dispatch: AppDispatch,
    contract: any,
    navigate: NavigateFunction,
    message: string,
    buttonLabel: string,
    contractName?: string,
  ) =>
    () => {
      dispatch(
        showConfirmation(message, contractName, [
          {
            displayName: 'Cancel',
          },
          {
            displayName: buttonLabel,
            isPrimary: true,
            onClick: async () => {
              await dispatch(deleteContract(contract));
              navigate('/contracts');
            },
          },
        ])
      );
    };

const deleteProgramHandler =
  (dispatch: AppDispatch, program: Program, message: string, buttonLabel: string, programName?: string) =>
    () => {
      dispatch(
        showConfirmation(message, programName, [
          {
            displayName: 'Cancel',
          },
          {
            displayName: buttonLabel,
            isPrimary: true,
            onClick: async () => await dispatch(deleteProgram(program)),
          },
        ])
      );
    };

interface ContentWrapperProps {
  popupMode?: boolean;
}

const ContentWrapper: React.FC<PropsWithChildren<ContentWrapperProps>> = ({ popupMode, children }) =>
  popupMode ? <>{ children }</> : <div className="container">{ children }</div>;

export interface ContractViewState {
  readMode?: boolean;
  popupMode?: boolean;
}

export const ContractView: React.FunctionComponent = () => {
  const params = useParams<ContractParams>();
  const location = useLocation();
  const navigate = useNavigate();
  const contractId = params.contractId!;
  const dispatch = useAppDispatch();
  const state = location.state as ContractViewState;
  const popupMode = state?.popupMode;
  const readMode = popupMode ?? state?.readMode;
  
  useEffect(() => {
    void dispatch(loadCarriers());
  }, [dispatch]);

  const [{ isHidden, message }, setPropagateContractState] = useState<{ isHidden: boolean, message: { text: string, type: AomMessageType } | null }>({ isHidden: true, message: null });
  const contractData = useParamsSelector(contractSelector, { id: contractId });
  const contract = mapContractToViewModel(contractData as ContractObj);
  const originalId = contract?.OriginalId ?? null;

  useEffect(() => {
    if (originalId) {
      void dispatch(loadContract(originalId));
    }
  }, [dispatch, originalId]);

  const canAddProgram = () => {
    return contract?.Status != ContractStatus.Active && contract?.Status != ContractStatus.Inactive && contract?.Status != ContractStatus.Archived;
  };

  const originalContract = useParamsSelector(contractSelector, { id: originalId });
  const carrierCodes = useParamsSelector(contractCarriersSelector, { id: contractId });
  const carriersLoadState = useSelector(carriersLoadStateSelector);
  const carriers = useSelector(carriersByCodeSelector(contract?.ValidatingCarriers || carrierCodes));
  const contractStatusProps = useParamsSelector(contractStatusPropsSelector, { id: contractId });

  if (carriersLoadState.type === LoadStateType.Error) {
    return <ErrorMessage message={ carriersLoadState.errorMessage } />;
  }

  if (
    carriersLoadState.type === LoadStateType.NotLoaded ||
    carriersLoadState.type === LoadStateType.Loading
  ) {
    return <AomLoader />;
  }

  const isOriginalContractActive =
    originalContract && originalContract.BusinessId === contract?.BusinessId && originalContract.Status === 1;
  return (
    <>
      { !popupMode && <AomBreadcrumbs /> }
      { isHidden ? <></> :
        <AomMessage type={ message?.type ?? AomMessageType.Warning } message={ <>{ message?.text }</> }
                    header={ '' }></AomMessage> }
      { !readMode && isOriginalContractActive && (
        <AomMessage
          type={ AomMessageType.Warning }
          message={
            <div>
              Please note there is another active version of the contract with the same contract ID.{ ' ' }
              {
                <Link
                  to={ combinePath(
                    '/contracts/:contractId',
                    { contractId: originalId }) }
                >
                  Click here
                </Link>
              }{ ' ' }
              to view the active contract.
            </div>
          }
        />
      ) }
      <ContentWrapper popupMode={ popupMode }>
        <h2>
          { readMode && (
            <Icon link size="small" name="arrow left" color="blue" onClick={ () => navigate(-1) } />
          ) }
          { contract?.Name }
          { !readMode && (
            <Dropdown direction="left" icon="ellipsis vertical" className="contract-action">
              <Dropdown.Menu>
                <Dropdown.Item
                  text="Changelogs"
                  onClick={ () =>
                    navigate(
                      combinePath(
                        '/contracts/:contractId/changelogs',
                        { contractId: contractId }
                      )
                    )
                  }
                />
                <Dropdown.Item
                  text="Clone"
                  onClick={ () =>
                    navigate(
                      combinePath(
                        '/contracts/:contractId/clone',
                        { contractId: contractId }
                      )
                    )
                  }
                />
                { contractStatusProps.versionComparison && (
                  <Dropdown.Item
                    text="Version Comparison"
                    onClick={ () =>
                      navigate(
                        combinePath(
                          '/contracts/:contractId/versionComparison',
                          { contractId: contractId }
                        )
                      )
                    }
                  />
                ) }
                { contractStatusProps.deactivate && (
                  <Dropdown.Item
                    text="Deactivate"
                    onClick={ getChangeStatusHandler(
                      dispatch,
                      Number(contractId),
                      'Are you sure you want to deactivate this contract?',
                      'Deactivate',
                      ContractStatus.Inactive,
                      contract?.Name
                    ) }
                  />
                ) }
                { contractStatusProps.delete && (
                  <Dropdown.Item
                    text="Delete"
                    onClick={ deleteContractHandler(
                      dispatch,
                      contract,
                      navigate,
                      'Are you sure you want to delete this contract? This action can not be undone.',
                      'Delete',
                      contract?.Name,
                      
                    ) }
                  />
                ) }
                { contractStatusProps.extend && (
                  <Dropdown.Item
                    text="Extend"
                    onClick={ () => {
                      navigate(
                        combinePath(
                          '/contracts/:contractId/extend',
                          { contractId: contract?.Id }
                        )
                      );
                    } }
                  />
                ) }
                <PropagateContractDropdownItems contractId={ contract.Id! } contractStatus={ contract.Status }
                                                callBack={ ({ isSuccessful, destinationEnvironment }) => {
                                                  const message = isSuccessful
                                                    ? { text: `This contract has been sent to ${ destinationEnvironment.toUpperCase() }`, type: AomMessageType.Success }
                                                    : { text: `Failed to sent this contract to ${ destinationEnvironment.toUpperCase() }`, type: AomMessageType.Warning }
                                                  setPropagateContractState({ isHidden: false, message });
                                                } } />
              </Dropdown.Menu>
            </Dropdown>
          ) }
          { !readMode && contractStatusProps.activate?.isAvailable && (
            <Button
              size="mini"
              color="blue"
              disabled={ !contractStatusProps.activate?.isEnabled }
              title={ contractStatusProps.activate?.disabledReason }
              primary
              className="edit-button"
              onClick={ getChangeStatusHandler(
                dispatch,
                Number(contractId),
                'Are you sure you want to activate this contract?',
                'Activate',
                ContractStatus.Active,
                contract?.Name
              ) }
            >
              Activate Contract
            </Button>
          ) }
          { !readMode && contractStatusProps.update && (
            <Button
              size="mini"
              basic
              color="blue"
              primary
              className="edit-button"
              onClick={ () => {
                navigate(
                  combinePath(
                    '/contracts/:contractId/update',
                    { contractId: contract?.Id }
                  )
                );
              } }
            >
              Update Contract
            </Button>
          ) }
          { !readMode && contractStatusProps.edit && (
            <Button
              size="mini"
              basic
              color="blue"
              primary
              className="edit-button"
              onClick={ () => {
                navigate(
                  combinePath('/contracts/:contractId/edit', {
                    contractId: contract?.Id,
                  })
                );
              } }
            >
              Edit Contract
            </Button>
          ) }
        </h2>
        { contract && (
          <Label>
            <Icon name="circle" size="tiny" color={ statusColorMap[contract.Status] } />{ ' ' }
            { ContractStatus[contract.Status] }
          </Label>
        ) }
        { contract?.Version ? <Label color="blue">Version { formatDecimal(contract.Version) }</Label> : null }
        { contract?.Type && <Label color="blue">{ contract.Type } Contract Type</Label> }
        <Grid className="no-margin-top">
          { contract?.CreatedBy && (
            <Grid.Column width={ 5 }>
              <div className="display-field">
                <label>Created By</label>
                <AuditUserInfo data={ contract?.CreatedBy } />
              </div>
            </Grid.Column>
          ) }
          { contract?.UpdatedBy && (
            <Grid.Column width={ 5 }>
              <div className="display-field">
                <label>Last Modified By</label>
                <AuditUserInfo data={ contract?.UpdatedBy } />
              </div>
            </Grid.Column>
          ) }
        </Grid>
        <Grid>
          { contract?.EndDate && (
            <Grid.Column width={ 5 }>
              <div className="display-field">
                <label>Contract End Date</label>
                {formatDate(contract?.EndDate, { format: 'DD MMMM YYYY' })}
              </div>
            </Grid.Column>
          ) }
        </Grid>
        <Grid>
          { contract?.FileName && (
            <Grid.Column>
              <div className="display-field">
                <label>Attached File</label>
                <AttachedFileInfo fileName={ contract.FileName } showIcon={ true } />
              </div>
            </Grid.Column>
          ) }
        </Grid>
        <Grid>
          { contract?.Accounts && (
            <Grid.Column>
              <div className="section-header">Customers Info</div>
              <ViewAccountTable selectedAccounts={ groupByLcn([], contract?.Accounts.accounts) } />
            </Grid.Column>
          ) }
        </Grid>
        <Grid className="no-margin-top">
          <Grid.Column width={ 5 }>
            <div className="section-header">Carrier (Airline)</div>
            { carriers.map((c) => (
              <div className="carrier-line" key={ c.code }>
                <Label circular color="blue">
                  { c.code }
                </Label>
                <span className="name-label">{ c.name }</span>
              </div>
            )) }
          </Grid.Column>
          { contract?.GdsInfos && contract.GdsInfos.length > 0 && (
            <Grid.Column width={ 11 }>
              <div className="section-header">GDS (Company Contract Distribution)</div>
              <GdsInfoView width={ 0 } gdsInfos={ contract?.GdsInfos } />
            </Grid.Column>
          ) }
        </Grid>
        <Grid>
          { contract?.ExcludedPccs?.length && (
            <Grid.Column>
              <div className="section-header">PCC Exclusions</div>
              <ExcludedPccsView pccs={ contract.ExcludedPccs } />
            </Grid.Column>
          ) || undefined }
        </Grid>
      </ContentWrapper>
      { (contract?.Programs?.length && (
          <Table selectable basic className="programs">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={ contractStatusProps.delete ? 4 : 5 }>Program name</Table.HeaderCell>
                <Table.HeaderCell width={ 2 }>Last ticket date</Table.HeaderCell>
                <Table.HeaderCell width={ 1 }>Version</Table.HeaderCell>
                <Table.HeaderCell width={ 2 }>Status</Table.HeaderCell>
                <Table.HeaderCell width={ 3 }>Created by</Table.HeaderCell>
                <Table.HeaderCell width={ 3 }>Last modified by</Table.HeaderCell>
                { !readMode && contractStatusProps.delete && (
                  <Table.HeaderCell width={ 1 }>Action</Table.HeaderCell>
                ) }
              </Table.Row>
            </Table.Header>
            <Table.Body>
              { contract?.Programs?.map(
                (p) =>
                  p && (
                    <Table.Row key={ p.Id }>
                      <Table.Cell>
                        { readMode ? (
                          p.ProgramName
                        ) : (
                          <Link
                            to={ combinePath(
                              '/contracts/:contractId/programs/:programId',
                              { contractId: contract?.Id, programId: p?.Id }) }
                          >
                            { p.ProgramName }
                          </Link>
                        ) }
                      </Table.Cell>
                      <Table.Cell>{ getLastTicketDate(p) }</Table.Cell>
                      <Table.Cell>{ formatDecimal(p.Version) }</Table.Cell>
                      <Table.Cell>
                        { typeof p.Status == 'number' && (
                          <Label>
                            <Icon name="circle" size="tiny" color={ programStatusColorMap[p.Status] } />{ ' ' }
                            { ProgramStatus[p.Status] }
                          </Label>
                        ) }
                      </Table.Cell>
                      <Table.Cell>
                        <AuditUserInfo data={ p?.CreatedBy } />
                      </Table.Cell>
                      <Table.Cell>
                        <AuditUserInfo data={ p?.UpdatedBy } />
                      </Table.Cell>
                      { !readMode && contractStatusProps.delete && (
                        <Table.Cell>
                          <div>
                            <Popup
                              trigger={
                                <Icon
                                  name="copy outline"
                                  data-testid="program-copy-icon"
                                  link
                                  onClick={ () => {
                                    navigate(
                                      combinePath(
                                        '/contracts/:contractId/programs/:programId/clone',
                                        { contractId: contract.Id, programId: p.Id }
                                      )
                                    );
                                  } }
                                />
                              }
                              position="bottom center"
                              content="Clone Program"
                            />
                            <Icon
                              name="trash alternate outline"
                              data-testid="program-delete-icon"
                              link
                              onClick={ deleteProgramHandler(
                                dispatch,
                                p,
                                'Are you sure you want to delete this program? This action can not be undone.',
                                'Delete',
                                p.ProgramName
                              ) }
                            />
                          </div>
                        </Table.Cell>
                      ) }
                    </Table.Row>
                  )
              ) }
            </Table.Body>
          </Table>
        )) ||
        undefined }
      { !readMode && contractStatusProps.activate?.disabledReason && (
        <AomMessage type={ AomMessageType.Warning } message={ contractStatusProps.activate?.disabledReason } />
      ) }
      { !readMode &&
        !contractStatusProps.activate?.disabledReason &&
        contract?.OriginalId &&
        contract?.Status === 0 && (
          <AomMessage
            type={ AomMessageType.Warning }
            message={ 'Check that all programs are correct before activating the contract' }
          />
        ) }
      { !readMode && canAddProgram() && (
        <div className="container buttons">
          <Button
            primary
            onClick={ () => {
              navigate(
                combinePath(
                  '/contracts/:contractId/programs/create',
                  params
                )
              );
            } }
          >
            <Icon name="plus circle" />
            Add Program
          </Button>
        </div>
      ) }
    </>
  );
};
